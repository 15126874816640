import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withModifiers as _withModifiers, withKeys as _withKeys, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-128ece5b"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "addon-view-full-text-list-title" }
const _hoisted_2 = { class: "addon-view-full-text-list-contents addon-view-full-text-list-fulltext" }
const _hoisted_3 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["addon-view-full-text-list", { current: _ctx.current }]),
    onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.onClick && _ctx.onClick(...args))),
    onKeydown: _cache[1] || (_cache[1] = _withKeys(_withModifiers(
//@ts-ignore
(...args) => (_ctx.onClick && _ctx.onClick(...args)), ["prevent"]), ["space","enter"])),
    tabindex: "0"
  }, [
    _createElementVNode("p", _hoisted_1, _toDisplayString(`${_ctx.content.id} ${_ctx.filename}`), 1),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("span", {
        class: "has-text-normal",
        innerHTML: _ctx.content.text
      }, null, 8, _hoisted_3)
    ])
  ], 34))
}