
import { defineComponent, onMounted, ref, computed } from 'vue'
import { useStore } from 'vuex'
import { useRoute } from 'vue-router'
import ButtonIcon from '@/components/molecules/ButtonIcon.vue'
import KeywordSearch from '@/components/molecules/KeywordSearch.vue'
import AddonViewFullTextList from './AddonViewFullTextList.vue'
import IconLoading from '@/components/atoms/IconLoading.vue'
import { FullTextSearchRequest } from '@/domain/snippet/FulltextSearchRequest'
import { sanitizeString } from '@/helpers/util/escapeCharacterUtils'

export default defineComponent({
  components: {
    ButtonIcon,
    KeywordSearch,
    AddonViewFullTextList,
    IconLoading,
  },
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  setup (props) {
    const store = useStore()
    const route = useRoute()
    if (!props.item.contentsBundles) return
    const currentBundle = computed(() => {
      if (!route.params.bundleIndex) {
        return props.item.contentsBundles[0]
      } else {
        return props.item.contentsBundles[Number(route.params.bundleIndex) - 1]
      }
    })

    const sortedContentsIdArray = computed(() => currentBundle.value.contents.map((c: any) => c.id))
    const keyword = ref('')
    const requestedKeyword = ref('')
    const blank = ''
    const extractPidNum = (pid: string) => {
      const prefix = 'info:ndljp/pid/'
      return pid.startsWith(prefix) ? pid.replace(prefix, blank) : pid
    }

    const isProcessing = computed(() => store.getters.addonViewFulltextProcessingFlag)

    const snippetSearch = (value: string) => {
      if (isProcessing.value) return
      const request: FullTextSearchRequest = {
        targets: [{
          pid: extractPidNum(props.item.pid),
          bids: [currentBundle.value.id],
        }],
        mode: 'CONTENT',
        sort: 'CONTENT',
        size: 10000,
      }
      const sp = ' '
      const FullWidthSpaceRegExp = /\u3000/g
      const keywords = value.replace(FullWidthSpaceRegExp, sp).split(sp)
      if (value !== blank && keywords.length !== 0) {
        request.keywords = keywords
      }
      store.dispatch('addonViewSnippetSearch', request)
      requestedKeyword.value = value
    }

    const snippetTotalHit = ref()
    const setSnippetTotalHit = (num: number) => {
      snippetTotalHit.value = num // ヒット件数
    }

    const results = computed(() => {
      if (!props.item.contentsBundles[route.params.bundleIndex ? Number(route.params.bundleIndex) - 1 : 0]) {
        // バンドルをまたぐ移動をした際にエラーとなるために回避する
        // FIXME
        return
      }
      const contents = props.item.contentsBundles[route.params.bundleIndex ? Number(route.params.bundleIndex) - 1 : 0].contents
      const data = store.getters.getItemViewFulltextSnippet
      const resultsArray = []
      if (data && data[extractPidNum(props.item.pid)]) {
        setSnippetTotalHit(data[extractPidNum(props.item.pid)].totalHit) // ヒット件数
        for (let i = 0; i < data[extractPidNum(props.item.pid)].contents.length; i++) {
          // どのコマか
          const c = data[extractPidNum(props.item.pid)].contents[i]
          const index = sortedContentsIdArray.value.indexOf(c.cid) + 1
          const cmeta = contents.find((content: any) => c.cid === content.id)
          const highlightFullText = (m: any): string => {
            // XSS対策のため、'<'と'>'をエスケープする
            const head = sanitizeString(m.head)
            const word = sanitizeString(m.word)
            const tail = sanitizeString(m.tail)
            return word ? head + '<em>' + word + '</em>' + tail : ''
          }
          for (let j = 0; j < c.matches.length; j++) {
            // コマ内で複数マッチする
            resultsArray.push({
              id: index,
              cid: c.cid,
              contents: cmeta, // タイトルなど
              text: highlightFullText(c.matches[j]),
            })
          }
        }
      }
      return resultsArray
    })

    const hasFullTextData = computed(() => {
      for (const checkBundle of props.item.contentsBundles) {
        if (checkBundle?.extra?.fulltextPath) {
          return true
        }
      }
      return false
    })

    onMounted(() => {
      snippetSearch('')
    })
    return {
      results,
      keyword,
      requestedKeyword,
      snippetSearch,
      isProcessing,
      snippetTotalHit,
      hasFullTextData,
    }
  },
})
